<template>
   <div class="card mb-0" ref="noticia" :tipo="String(noticia.tipo).toLowerCase()">
      <div class="card-buttons-top align-items-center">
         <div class="row justify-content-center">
            <a class="badge badge-default cursor-pointer" :href="url +'/formulario/'+ noticia.id" target="_blank" v-if="noticia.tipo == 'Formulário'">
               <i class="fal fa-external-link-alt font-11 me-1"></i> Visualizar
            </a>
            <a class="badge badge-default cursor-pointer" :href="url +'/noticia/'+ noticia.id" target="_blank" v-else-if="noticia.tipo == 'Noticia'">
               <i class="fal fa-external-link-alt font-11 me-1"></i> Visualizar
            </a>
            <a class="badge badge-default cursor-pointer" :href="noticia.hotsite" target="_blank" v-else-if="noticia.hotsite">
               <i class="fal fa-external-link-alt font-11 me-1"></i> Visualizar
            </a>
            <div class="badge badge-default cursor-pointer" @click="editar">
               <i class="fal fa-pen font-11 me-1"></i> Editar
            </div>
            <div class="badge badge-default-outline cursor-pointer border-danger text-red" @click="remover">
               <i class="far fa-trash font-11 me-1"></i> Remover
            </div>
         </div>
      </div>
      <img :src="noticia.banner == null ? '' : noticia.banner" class="card-img-top" alt="noticia" @error="imageError">
      <div class="card-body">
         <h5 class="limitador">{{ noticia.titulo == null || noticia.titulo.trim().length == 0 ? 'Cód. '+ String(noticia.tags).substring(0, 2) +"-"+ String(noticia.tags).substring(2, String(noticia.tags).length) : noticia.titulo }}</h5>
         <span class="text-secondary">{{ noticia.data.dayOfMonth.toString().padStart(2, '0') +'/'+ noticia.data.monthValue.toString().padStart(2, '0') +'/'+ noticia.data.year }} {{ noticia.data.hour.toString().padStart(2, '0') +':'+ noticia.data.minute.toString().padStart(2, '0') }}</span>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
   name: 'Noticia',
   props: ['noticia', 'index'],
   data: function () {
      return {
         url: window.location.origin
      }
   },
   computed: {
		...mapState({
			urlRest: state => state.urlRest
		})
	},
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      editar : function () {
         this.$emit('editar', this.noticia)
      },
      remover : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Remover postagem?',
            confirmButtonText: 'Confirmar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'adminService/removeNoticia',
                  params:{
                     id: this.noticia.id
                  }
               }).then(() => {
                  this.$refs.noticia.style.display = 'none'

                  this.$toast.fire({
                     icon: 'success',
                     title: 'Notícia removida!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogarAdmin')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         });
      }
   }
}

</script>

<style scoped>

.card-buttons-top {
   opacity: 0;
   display: flex;
   justify-content: center;
   align-items: center;
   position: absolute;
   top: 0;
   bottom: 70px;
   left: 0;
   right: 0;
   background: #fffa;
   transition: opacity 0.3s;
}

.card:hover .card-buttons-top {
   opacity: 1;
}

.badge {
   width: max-content;
   margin: 5px;
}

h5 {
   font-size: 15px !important;
   margin-bottom: 5px !important;
}

span {
   font-size: 12px !important;
   display: block;
}

.card[tipo="noticia"] img {
	aspect-ratio: 3/2;
	object-fit: cover;
}

.card[tipo="produto"] img {
	aspect-ratio: 1/1;
	object-fit: contain;
}

</style>